import React, { Component } from "react";

import SweetAlert from "sweetalert-react";

import "sweetalert/dist/sweetalert.css";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import { Creators as CartCreator } from "../../store/ducks/cart";

import Currency from "../../utils/currency";

import { FaArrowLeft, FaCheck, FaTimesCircle, FaTimes } from "react-icons/fa";

import "./styles.css";

import "reactjs-popup/dist/index.css";

import Header from "../../components/Header";

import Popup from "reactjs-popup";

import { toast } from "react-toastify";

import api from "../../services/api";

import iconMoney from "../../assets/icon-money.png";

import iconCart from "../../assets/icon-cart.png";
import iconPix from "../../assets/icon-pix.png";

class ScreenCheckout extends Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    let empresa = localStorage.getItem("@comer-aqui/empresa");

    empresa = JSON.parse(empresa);

    let taxaDeServico = empresa.vlTaxaentrega;

    let total = parseFloat(props.cart.totalprice) + parseFloat(taxaDeServico);

    let { agendamento } = props.location;

    this.state = {
      empresa: empresa,
      isMoney: false,
      isCart: false,
      isPix: false,
      show: false,
      showInput: false,
      thing: 0,
      client: props.cart.client,
      taxaDeServico,
      total,
      agendamento,
      items: props.location.items,
      id_cupom: "",
      descricao: "",
      cod_cupom: "",
      ds_cupom: "",
      vl_cupom: 0,
      porc_cupom: "",
      tp_desconto: "",
      vl_min_pedido: "",
      dt_validade: "",
      discount: 0,
      discountpr: 0,
      show_cupom: false,
      show_remover_cupom: false,
      disable: false,
    };

    this.handleSubmitCupom = this.handleSubmitCupom.bind(this);
    this.handleChangeCodCupom = this.handleChangeCodCupom.bind(this);
  }

  changeScreenForStatus = async () => {
    try {
      // if (!this.props.cart.totalprice || this.props.cart.totalprice <= 0) {
      //   toast.error(
      //     "Nenhum item encontrado, por favor adicione item ao seu carrinho!"
      //   );
      //   return;
      // }

      let id_cliente = this.props.cart.client.id;

      let empresa = localStorage.getItem("@comer-aqui/empresaUrl");
      let objEmpresa = localStorage.getItem("@comer-aqui/empresa");

      let { taxaDeServico, vl_cupom, agendamento } = this.state;

      let thing = this.refs.thing ? String(this.refs.thing.value) : "";

      // if(this.state.tp_desconto == 1) {
      //     this.props.cart.totalprice = parseFloat(this.props.cart.totalprice) + parseFloat(taxaDeServico) - parseFloat(vl_cupom);
      // } else if(this.state.tp_desconto == 2) {
      //     this.props.cart.totalprice = parseFloat(this.props.cart.totalprice) + parseFloat(taxaDeServico) - parseFloat(this.state.total) * (parseFloat(this.state.vl_cupom / 100))
      //     console.log(`

      //     parseFloat(this.state.total) = ${parseFloat(this.state.total)} +
      //     parseFloat(taxaDeServico) = ${parseFloat(taxaDeServico)} -
      //     parseFloat(this.state.total) = ${parseFloat(this.state.total)} *
      //     (parseFloat(this.state.vl_cupom / 100)) = ${(parseFloat(this.state.vl_cupom / 100))}

      //     ${parseFloat(this.state.total)} + ${parseFloat(taxaDeServico)} - ${parseFloat(this.state.total)} *  ${(parseFloat(this.state.vl_cupom / 100))}

      //     total = ${this.props.cart.totalprice }

      //     `)

      //     // this.props.cart.totalprice = parseFloat(this.state.total) * (parseFloat(this.state.vl_cupom / 100))

      // } else {
      //     this.props.cart.totalprice = parseFloat(this.props.cart.totalprice) + parseFloat(taxaDeServico)
      // }

      // console.log("antes =", this.props.cart.totalprice)
      // console.log("total =", this.state.total)
      // console.log("tp_desconto = ", this.state.tp_desconto)
      // console.log(this.state)

      // if (this.state.tp_desconto == 1) {
      //     this.props.cart.totalprice =
      //       parseFloat(this.props.cart.totalprice) +
      //       parseFloat(taxaDeServico) -
      //       parseFloat(vl_cupom);
      //   } else if (this.state.tp_desconto == 2) {
      //     // this.props.cart.totalprice =
      //     //   parseFloat(this.state.total) -
      //     //   parseFloat(this.state.total) * parseFloat(this.state.vl_cupom / 100)
      //     this.props.cart.totalprice = this.state.total

      //   } else {
      //     this.props.cart.totalprice =
      //       parseFloat(this.props.cart.totalprice) + parseFloat(taxaDeServico);
      //   }
      //   console.log("depois =", this.props.cart.totalprice)
      //   console.log("depois total =", this.state.total)

      this.props.cart.totalprice = this.state.total;

      if (this.props.cart.buscarOuEntregarAqui == "ENTREGAR_AQUI") {
        // ENTREGAR_AQUI = 4
        this.props.cart.typeorder = 4;
      } else {
        // VOU_BUSCAR = 2
        this.props.cart.typeorder = 2;
      }

      objEmpresa = JSON.parse(objEmpresa);

      let troco = 0;
      
      if (thing) {
        troco = thing
      }


      let forma = "DINHEIRO";

      let { isCart, isPix } = this.state;

      if (isPix) {
        forma = "PIX";
      }

      if (isCart) {
        forma = "CARTAO";
      }

      /*  Gabriel - 16/04/2022 - A alteração no cart.js torna este laço desnecessário e
                                       corrige o erro nas exclusões dos itens do carrinho 
            let { items } = this.state
            // let { items } = this.props.cart

            let i = 1;
            for (let item of items) {
                item.id = i;
                //item.product.id = i;
                item.product.iditem = i;

                if (item.add) {
                    for (let add of item.add) {
                        add.iditem = i;
                    }
                }
                if (item.remove) {
                    for (let remove of item.remove) {
                        remove.iditem = i;
                    }
                }

                if (item.product.note) {
                    for (let note of item.product.note) {
                        if (note.observacao) {
                            for (let observacao of note.observacao) {
                                if (observacao.itens) {
                                    for (let item of observacao.itens) {
                                        item.iditem = i;
                                    }
                                }
                            }
                        }
                    }
                }

                /* if (item.product.items) {
                     for (let product_item of item.product.items) {
                         product_item.iditem = i;
                     }
                 }
 
                 item.product.sabores = item.product.items;
 
                 delete item.product.items; */

      /* i++;
            }

            this.props.cart.items = items;*/
      let params = {
        codempresa: objEmpresa.codEmpresa,
        ...this.props.cart,
        id_cliente,
        empresa,
        agendamento: agendamento.agendamento,
        dataagend: agendamento.data,
        horaagend: agendamento.hora,
        forma,
        troco,
        aditional: taxaDeServico,
        cod_cupom: "",
        discountpr: this.state.discountpr,
        discount: this.state.discount,
        token: localStorage.getItem("tokenValido"),
      };

      // localStorage.removeItem("tokenValido")
      // console.log("props =", this.state)

      // console.log(params)

      try {
        const response = await api.post(`/pedidos`, params, {
          headers: { "Content-Type": "application/json" },
        });
        localStorage.removeItem("tokenValido");
        this.props.checkOut();

        this.props.history.push("/status", {
          client: this.state.client,
          idpedido: response.data,
          total: this.state.total,
          buscarOuEntregarAqui: this.props.location.buscarOuEntregarAqui,
        });
      } catch(erro){
        //
        console.log(erro)
        toast.error("Ocorreu erro na requisição, tente novamente.");
        this.setState({
          disable: false,
        });
      }

      //this.props.history.push('/?id=' + localStorage.getItem('@comer-aqui/authorization'));

      //localStorage.removeItem('@comer-aqui/authorization');
      //localStorage.removeItem('@comer-aqui/numberOrder');
    } catch (error) {
      setTimeout(() => {
        this.setState({
          disable: false,
        });
      }, 3000);

      toast.error("Ocorreu erro na requisição");
      // toast.error(error.response.data)
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.state.isMoney && !this.state.isCart && !this.state.isPix) {
      toast.error("Qual a forma de pagamento? Dinheiro ou Cartão?");
      return;
    }

    this.setState({ show: this.state.isMoney });

    if (this.state.isCart || this.state.isPix) {
      this.changeScreenForStatus();
    }

    this.setState({
      disable: true,
    });
  };

  handleSelectTypePayment = (type) => {
    this.setState(
      { isMoney: type === "D", isCart: type === "C", isPix: type === "P" },
      () => {}
    );
  };

  handleThing = () => {
    let thing = this.refs.thing.value;

    thing = String(thing).replace(",", "");
    thing = String(thing).replace(".", "");
    thing = String(thing).replace(".", "");
    thing = String(thing).replace(".", "");

    let formatPrice = (thing / 100).toFixed(2).replace(".", ",");
    thing = formatPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    // console.log("formatPrice =", formatPrice)

    thing = String(thing).replace(".", "");
    thing = String(thing).replace(".", "");
    thing = String(thing).replace(".", "");
    thing = String(thing).replace(",", ".");

    if (parseFloat(thing) < parseFloat(this.props.cart.totalprice)) {
      toast.error("Valor deve ser maior que o total!");
      return;
    }

    if (thing === "") {
      toast.error("Troco não informado!");
      return;
    }

    this.setState({ showInput: false, show: false });
    this.changeScreenForStatus();
  };

  async handleSubmitCupom(e) {
    e.preventDefault();

    try {
      let { empresa } = this.state;

      this.state.total = this.subtotal + this.taxaDeServico;

      if (!this.state.cod_cupom) {
        toast.error("Insira o código de cupom");
        return;
      }
      const response = await api.get(
        `/validacupom?cod_empresa=${empresa.codEmpresa}&cupom=${this.state.cod_cupom}`
      );

      this.setState({
        vl_cupom: response.data.vldesconto,
        tp_desconto: response.data.tpdesconto,
      });

      let totalMod = parseFloat(this.props.cart.subtotal);

      // console.log("1", totalMod)

      if (this.state.tp_desconto == 1) {
        totalMod = totalMod - this.state.vl_cupom;
        this.state.discount = this.state.vl_cupom;
        this.state.discountpr = 0;

        // console.log("2", totalMod)
      }
      if (this.state.tp_desconto == 2) {
        let porcen = totalMod * (this.state.vl_cupom / 100);
        totalMod = totalMod - porcen;
        this.state.discount = porcen;
        this.state.discountpr = this.state.vl_cupom;

        // console.log("3", totalMod)
        //     this.props.cart.totalprice = this.state.total
      }

      let newTotal = totalMod + parseFloat(this.state.taxaDeServico);

      // console.log("4", newTotal)

      this.setState({
        ds_cupom: this.state.cod_cupom,
        show_cupom: true,
        total: newTotal,
      });
    } catch (error) {
      toast.error("Cupom invlálido");
    }
  }

  handleChangeCodCupom(e) {
    this.setState({ cod_cupom: e.target.value });

    if (!this.state.cod_cupom) {
      this.setState({
        show_cupom: false,
        show_remover_cupom: true,
      });
    }
  }

  render() {
    if (this.props.cart.buscarOuEntregarAqui == "VOU_BUSCAR") {
      this.state.total -= this.state.taxaDeServico;
      this.state.taxaDeServico = 0;
    }

    let { total, taxaDeServico, vl_cupom, ds_cupom, tp_desconto } = this.state;

    const isNumeric = (value) => {
      var regra = /^[0-9]+$/;

      if (String(value).match(regra)) {
        return true;
      }

      return false;
    };

    const formatPrice = (value) => {
      if (value) {
        let val = (value / 100).toFixed(2).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
      return 0;
    };

    const formatValueOnKeyUp = (value) => {
      value = value.replace(".", "");
      value = value.replace(".", "");
      value = value.replace(".", "");
      value = value.replace(",", "");
      if (!isNumeric(value)) {
        return 0;
      }
      return formatPrice(value);
    };

    const handleCupomKeyUp = (value) => {
      if (!this.state.cod_cupom) {
        this.setState({
          vl_cupom: 0,
          show_cupom: false,
          show_remover_cupom: false,
        });
        return value;
      }
    };

    return (
      <>
        <Header apenasLogo={true}/>
        <SweetAlert
          show={this.state.show}
          title="Troco"
          text="Precisa de troco?"
          confirmButtonText="Sim"
          cancelButtonText="Não"
          showCancelButton
          onConfirm={() => {
            this.setState({ show: false, showInput: true });
          }}
          onCancel={() => {
            this.setState({ show: false });
            this.changeScreenForStatus();
          }}
        />
        <Popup
          open={this.state.showInput}
          modal
          contentStyle={{
            maxWidth: "100%",
            width: "90%",
          }}
          onClose={() => {

            this.setState({
              disable: false,
            });
            this.setState({ showInput: false })
          }}
        >
          <div className="container pt-5 pb-5 row m-0">
            <div className="col-md-12 col-xl-12 col-sm-12 col-12 mb-2 text-right">
              <button
                className="btn btn-circle btn-danger"
                onClick={() => this.setState({ showInput: false })}
              >
                Fechar <FaTimesCircle />
              </button>
            </div>
            <div className="col-md-12 col-xl-12 col-sm-12 col-12 mb-2">
              <h6>Troco para</h6>
              <input
                type="text"
                ref="thing"
                onKeyUp={(e) =>
                  (this.refs.thing.value = formatValueOnKeyUp(e.target.value))
                }
                placeholder="Troco para"
                className="form-control"
              />
            </div>
            <div className="col-md-12 col-xl-12 col-sm-12 col-12">
              <button
                className="btn btn-block btn-success"
                onClick={this.handleThing.bind(this)}
              >
                Confirmar <FaCheck />
              </button>
            </div>
          </div>
        </Popup>
        <div id="container-checkout">
          <form className="row mt-3 mb-1" onSubmit={this.handleSubmitCupom}>
            <div className="col pr-0">
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  id="handle-cupom"
                  className="form-control w-100"
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    position: "relative",
                    paddingRight: "42px",
                  }}
                  value={this.state.cod_cupom}
                  onChange={this.handleChangeCodCupom}
                  onKeyUp={(e) =>
                    (this.value = handleCupomKeyUp(e.target.value))
                  }
                  placeholder="Código de cupom"
                />
                {this.state.show_remover_cupom && (
                  <span
                    className="btn btn-link text-danger btn-remover-cupom"
                    onClick={(e) => {
                      this.state.total =
                        parseFloat(this.props.cart.totalprice) +
                        parseFloat(this.state.taxaDeServico);

                      this.setState({
                        cod_cupom: "",
                        vl_cupom: 0,
                        show_remover_cupom: false,
                        show_cupom: false,
                      });
                    }}
                    style={{ position: "absolute", top: 0, right: 0 }}
                  >
                    <FaTimes />
                  </span>
                )}
              </div>
            </div>
            <div className="col-auto pl-0" style={{ marginBottom: 10 }}>
              <button
                type="submit"
                className="btn btn-success btn-cupom"
                style={{ boxShadow: "none!important" }}
              >
                Adicionar
              </button>
            </div>
          </form>

          <div className="container-info-checkout">
            <span
              className="title-info-total"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: 15,
                fontWeight: 600,
              }}
            >
              Valor total dos itens:
              <span style={{ marginLeft: 10, fontWeight: 500 }}>
                {Currency.formatterValue.format(this.props.cart.subtotal)}
              </span>
            </span>

            <span
              className="title-info-total "
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: 13,
              }}
            >
              {" "}
              Desconto:
              <span style={{ marginLeft: 10, fontWeight: 500 }}>
                {this.state.show_cupom ? (
                  <>
                    {this.state.cod_cupom === this.state.ds_cupom && (
                      <>
                        {this.state.tp_desconto == 1 && (
                          <>
                            <div>
                              <b>
                                {" "}
                                {Currency.formatterValue.format(
                                  this.state.vl_cupom
                                )}
                              </b>
                            </div>
                          </>
                        )}

                        {this.state.tp_desconto == 2 && (
                          <>
                            <div>
                              <b style={{ color: "#3e9a2c" }}>
                                {" "}
                                {this.state.vl_cupom}%{" "}
                              </b>{" "}
                              {Currency.formatterValue.format(
                                parseFloat(this.props.cart.subtotal) *
                                  (this.state.vl_cupom / 100)
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  "R$ 0,00"
                )}
              </span>
            </span>

            <span
              className="title-info-total"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: 13,
              }}
            >
              {" "}
              Taxa de serviço:
              <span style={{ marginLeft: 10, fontWeight: 500 }}>
                {Currency.formatterValue.format(taxaDeServico)}
              </span>
            </span>

            <span className="title-info-total-final"> </span>

            <div className="d-flex align-items-center justify-content-between">
              <span>
                <b>Total do pedido:</b>
              </span>
              <span className="label-totals" style={{ fontSize: 20 }}>
                {" "}
                {Currency.formatterValue.format(total)}
              </span>
            </div>

            {/* Cesar - 27/09/2022
                        <form className='row mt-3 mb-1' onSubmit={this.handleSubmitCupom}>
                            <div className='col pr-0'>
                                <div style={{ position: 'relative' }}>
                                    <input 
                                        type='text' 
                                        id='handle-cupom'
                                        className='form-control w-100'
                                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, position: 'relative', paddingRight: '42px' }}
                                        value={this.state.cod_cupom}
                                        onChange={this.handleChangeCodCupom}
                                        onKeyUp={(e) => this.value = handleCupomKeyUp(e.target.value) }
                                        placeholder='Código de cupom'
                                    />
                                    {this.state.show_remover_cupom && (
                                        <span 
                                            className='btn btn-link text-danger btn-remover-cupom' 
                                            onClick={(e) => this.setState({
                                                cod_cupom: '',
                                                vl_cupom: 0,
                                                show_remover_cupom: false,
                                                show_cupom: false 
                                            })}
                                            style={{ position: 'absolute', top: 0, right: 0 }}
                                        >
                                            <FaTimes />
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className='col-auto pl-0'>
                                <button
                                    type='submit'
                                    className='btn btn-success btn-cupom'
                                    style={{ boxShadow: 'none!important' }}
                                >
                                    Adicionar
                                </button>
                            </div>
                        </form>  */}

            {/* <div className='d-flex align-items-center justify-content-between mb-3'>
                            {this.state.show_cupom && (
                                <>
                                    {this.state.cod_cupom === this.state.ds_cupom && (
                                        <>
                                            {this.state.tp_desconto == 1 && (
                                                <>
                                                    <div><b>Desconto: {Currency.formatterValue.format(this.state.vl_cupom)}</b></div>
                                                    <div><span className="m-0 label-totals d-flex align-items-center justify-content-end">Total: {Currency.formatterValue.format(parseFloat(total) - parseFloat(this.state.vl_cupom))}</span></div>
                                                </>
                                            )}

                                            {this.state.tp_desconto == 2 && (
                                                <>
                                                    <div><b>Desconto: {this.state.vl_cupom}%</b></div>
                                                    <div><span className="m-0 label-totals d-flex align-items-center justify-content-end">Total: {Currency.formatterValue.format( parseFloat(total) - parseFloat(total) * (parseFloat(this.state.vl_cupom / 100)) )}</span></div>
                                                </>
                                            )}

                                        </>
                                    )}
                                </>
                            )} 
                        </div> */}

            <fieldset className="mt-0">
              <h4 className="text-center mb-0">
                <b>Vou pagar em</b>
              </h4>

              <ul className="items-payment">
                <li
                  className={this.state.isMoney ? "selected" : ""}
                  onClick={() => this.handleSelectTypePayment("D")}
                >
                  <img src={iconMoney} alt="Dinheiro" />
                  <span>Dinheiro</span>
                </li>
                <li
                  className={this.state.isCart ? "selected" : ""}
                  onClick={() => this.handleSelectTypePayment("C")}
                >
                  <img src={iconCart} alt="Cartão" />
                  <span>Cartão</span>
                </li>

                {this.state.empresa.tpUtilizapix === 1 && (
                  <li
                    className={this.state.isPix ? "selected" : ""}
                    onClick={() => this.handleSelectTypePayment("P")}
                  >
                    <img src={iconPix} alt="Pix" width={40} height={40} />
                    <span> Pix </span>
                  </li>
                )}
              </ul>
            </fieldset>
          </div>
          <div className="fixed-bottom">
            <div className="row">
              <div className="col-md-12 col-xl-12 col-sm-12 col-12 p-0">
                <button
                  className="btn btn-block btn-success p-3"
                  onClick={this.handleSubmit}
                  disabled={this.state.disable}
                >
                  Finalizar <FaCheck />
                </button>
              </div>
              <div className="col-md-12 col-xl-12 col-sm-12 col-12 p-0">
                <button
                  className="btn btn-block btn-dark btn-go-back"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  <FaArrowLeft /> Voltar 
                </button> 
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ cart: state.cart, client: state.client });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CartCreator, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenCheckout);
